import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const config = {
  apiUrl: "https://sae6795j8l.execute-api.eu-west-1.amazonaws.com/dev/api/v1/"
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      message: null,
      isListsLoaded: false,
      isItemLoaded: false,
      isItemTaken: false,
      lists: [],
      selectedList: null,
      nextItem: null
    };
  }

  componentDidMount() {
    fetch(config.apiUrl + "lists/")
      .then(res => res.json())
      .then(
        result => {
          this.setState({ lists: result.Items, isListsLoaded: true });
        },
        error => {
          this.setState({
            isListsLoaded: true,
            error: error
          });
        }
      );
  }

  fetchNextFromList(listname, extraState = {}) {
    fetch(config.apiUrl + "nextfrom/" + listname)
      .then(res => res.json())
      .then(result => {
        var newState = {
          nextItem: result.name,
          selectedList: listname,
          isItemLoaded: true
        };
        this.setState(Object.assign(newState, extraState));
      });
  }

  handleListClick(listname) {
    this.fetchNextFromList(listname);
  }

  handleNameClick(listname, name) {
    this.setState({
      message: "Loading..."
    })
    // Mark the name as taken by performing a PUT
    fetch(config.apiUrl + "lists/" + listname + "/names/" + name + "/", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        taken: "y"
      })
    })
      .then(
        this.setState({
          message: "Name '" + name + "' successfully taken!",
          nextItem: "..."
        })
      )
      .then(() => {
        this.fetchNextFromList(listname);
      });
  }

  render() {
    return (
      <div>
        <Nav
          selectedList={this.state.selectedList}
          lists={this.state.lists}
          onClick={l => this.handleListClick(l)}
        />
        <Container fluid="true">
          {this.state.selectedList ? (
            <NameBody
              {...this.state}
              onClick={(l, n) => this.handleNameClick(l, n)}
            />
          ) : (
            <ListNames {...this.state} onClick={l => this.handleListClick(l)} />
          )}
        </Container>
      </div>
    );
  }
}

function Nav(props) {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">Namegenerator</Navbar.Brand>
      {props.selectedList && (
        <NavDropdown title={props.selectedList || "Lists"} id="lists-dropdown">
          {props.lists.map(item => (
            <NavDropdown.Item
              key={"nav-" + item}
              onClick={() => props.onClick(item)}
            >
              {item}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      )}
    </Navbar>
  );
}

function NameSelector(props) {
  return (
    <div>
      {props.error && <Alert variant="danger">{props.error.message}</Alert>}
      {props.message && <Alert variant="success">{props.message}</Alert>}
      <div>
        <p>
          {props.isItemLoaded ? (
            <Form>
              <h3>The next name is '{props.nextItem}'</h3>
              <Button
                onClick={() =>
                  props.onClick(props.selectedList, props.nextItem)
                }
                disabled={props.nextItem === '...'}
              >
                Take!
              </Button>
            </Form>
          ) : (
            "Loading..."
          )}
        </p>
      </div>
    </div>
  );
}

function ListNames(props) {
  return (
    <div>
      {props.isListsLoaded
        ? props.lists.map((item, index) => (
            <Row key={"listname-" + index}>
              <Col>
                <Button variant="link" onClick={() => props.onClick(item)} >
                  {item}
                </Button>
              </Col>
            </Row>
          ))
        : "Loading..."}
    </div>
  );
}

function NameBody(props) {
  return (
    <Row>
      <Col>
        <NameSelector {...props} />
      </Col>
    </Row>
  );
}

ReactDOM.render(<Home />, document.getElementById("root"));
